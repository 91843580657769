<template>
	<div class='pt79 pl481 text-center pb100'>
		<div class="box">
			<div class="color3 fs46 mb58 fw-600">{{info.skillName}}</div>
			<!-- <img :src="info.pcPic" class="goods_img w-max"> -->
			<div class="w-max text-left mt50 fs18 ql-editor" v-html="info.skillCont">
			</div>
		</div>
	</div>
</template>

<script>
	import { getSkillDetails } from '@/api'
	export default {
		data() {
			return {
				info: {}
			};
		},

		components: {},

		created() {
			this.getSkillDetails(this.$route.params.id)
		},

		mounted() {},

		methods: {
			async getSkillDetails(id) {
				const res = await getSkillDetails({ id })
				this.info = res.data
			}
		}
	}
</script>
<style lang='scss' scoped>
	.box {
		width: 1339px;
		margin-top: -350px;
	}

	.goods_img {
		height: 560px;
	}
</style>
